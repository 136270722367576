<template>
  <div class="vg_wrapper">
    <el-tabs v-model="activeName" type="border-card">
      <el-tab-pane label="单据信息" name="first" :key="'first'">
        <childTab1 @isShow="isShow" @isYongYou="isYongYou" ref="childTab1" />
      </el-tab-pane>
      <el-tab-pane label="审批流转" name="second" :key="'second'">
        <childTab2 :isShow="isSho" :isYongYouFlag="isYongYouFlag" @draftState="draftState" />
      </el-tab-pane>
      <el-tab-pane label="相关文档" name="third" :key="'third'">
        <childTab3 />
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
// 自己确定文件路径
import OinvEditMain from '@/views/SettleManagement/OinvManage/TabChild/OinvEditMain';
import OinvEditAppr from '@/views/SettleManagement/OinvManage/TabChild/OinvEditAppr';
import OinvEditAbout from '@/views/SettleManagement/OinvManage/TabChild/OinvEditAbout';
export default {
  name: 'OinvEdit',
  components: {
    childTab1: OinvEditMain,
    childTab2: OinvEditAppr,
    childTab3: OinvEditAbout
  },
  data() {
    return {
      activeName: 'first',
      isSho: true,
      isYongYouFlag: false
    };
  },
  created() {},
  methods: {
    isShow(val) {
      this.isSho = val;
    },
    draftState() {
      this.$refs.childTab1.initData();
    },
    isYongYou(val) {
      this.isYongYouFlag = val;
    }
  }
};
</script>

<style scoped></style>
