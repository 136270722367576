import { render, staticRenderFns } from "./OinvEditMain.vue?vue&type=template&id=1ae06f56&scoped=true&xmlns=http%3A%2F%2Fwww.w3.org%2F1999%2Fhtml&"
import script from "./OinvEditMain.vue?vue&type=script&lang=js&"
export * from "./OinvEditMain.vue?vue&type=script&lang=js&"
import style0 from "./OinvEditMain.vue?vue&type=style&index=0&id=1ae06f56&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1ae06f56",
  null
  
)

export default component.exports